import React, { useState, useEffect } from 'react';
import { useRemoveItemFromCart, useUpdateItemQuantity } from '../../context/storeContext';

import iconTrash from '../../images/icon-trash-alt.svg';
import CurrencySwitcher from '../Header/currencyswitcher/CurrencySwitcher';

export default ({ item: cart }) => {
  const removeItemFromCartById = useRemoveItemFromCart();
  const updateItemQuantityById = useUpdateItemQuantity();

  const [quantity, setQuantity] = useState(cart.quantity);

  const handleQuantityChanged = (lineItemId, changeAmount) => {
    var updatedQuantity = quantity + changeAmount;

    if (updatedQuantity > 0) {
      updateItemQuantityById(lineItemId, updatedQuantity);
      setQuantity(updatedQuantity);
    }
  };

  const image_source = cart.image;

  return (
    <div className="cart-product">
      <div className="cart-product-image">
        <img src={image_source} alt="Product Image" />
      </div>
      <div className="cart-product-contents">
        <div className="cart-content-top">
          <div className="cart-product-name">{cart?.title || 'N/A'}</div>
          <div className="cart-product-clear">
            <a href="#" onClick={() => removeItemFromCartById(cart.id)}>
              <img src={iconTrash} alt="Trash Icon" /> Remove
            </a>
          </div>
        </div>
        <div className="cart-content-bottom">
          {!!cart?.customAttr
            ? Object.entries(cart.customAttr).map((attribute) => {
                const key = attribute[0] || '';
                const value = attribute[1] || '';

                return (
                  <span className="cart-product-color">
                    {key}: <span className="cart-product-attribute">{value}</span>
                  </span>
                );
              })
            : null}
          <span className="cart-product-amount">
            Amount
            <span className="cart-number">
              <span
                className="cart-plus"
                onClick={() => handleQuantityChanged(cart?.id, 1)}
                onKeyPress={() => {}}
                tabIndex={-1}
              >
                +
              </span>
              <input type="text" value={`${quantity}`} onChange={() => {}} />
              <span
                className="cart-minus"
                onClick={() => handleQuantityChanged(cart?.id, -1)}
                onKeyPress={() => {}}
                tabIndex={-1}
              >
                -
              </span>
            </span>
          </span>
          <span className="cart-product-subtotal">
            Subtotal{' '}
            <span className="cart-subtotal-amount">
              <CurrencySwitcher price={cart?.price?.amount * quantity} />
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
