import React, { useContext } from 'react';
import { Link } from 'gatsby';

import MegaMenuSubTabItem from './MegaMenuSubTabItem';
import { AppContext } from '../../../../apollo/wrap-root-element';

export default function MegaMenuSubTab({
  data,
  isActive,
  viewAllLink,
  shouldSliceData = true,
  sliceLength,
  activeSubTab,
}) {
  const { selectedSeasonForTab, setSelectedSeason } = useContext(AppContext);
  // We need to show at max eight items for each sub-section.
  // For watch section slice data to 4 items
  let slicedItem = !!data ? data : [];
  if (shouldSliceData) {
    slicedItem = data?.slice(0, sliceLength);
  }
  const onLinkClicked = () => {
    viewAllLink.includes('bts') && setSelectedSeason(activeSubTab);
  };

  return (
    !!slicedItem?.length && (
      <div className={`tab-content-inner ${isActive ? 'active' : 'd-none'}`}>
        <div className="wrapper wrapper-md">
          <div className="tab-items columns d-flex">
            {slicedItem.map((item, index) => (
              <MegaMenuSubTabItem key={index} data={item} isBlog columns={data.column || 4} />
            ))}
          </div>
          {!!viewAllLink && (
            <div className="explore-link">
              <Link to={viewAllLink} className="btn btn-explore" onClick={onLinkClicked}>
                View All
              </Link>
            </div>
          )}
        </div>
      </div>
    )
  );
}
