import React, { useState } from 'react';

import MegaMenuTab from './MegaMenuTab';
import sectionNameToUrlMap from '../../../../map/readSectionNameToURLMap';

const tabNameToTitleMap = {
  'season-1': 'season 1',
  'season-2': 'season 2',
  'season-3': 'season 3',
  blog: 'blog',
};

export default function ReadMegaMenu({ isShown, close, menuData }) {
  // This is used to add activeTab in header.
  const [activeTab, setActiveTab] = useState('season-1');

  return (
    <div className={`mega-menu ${isShown ? 'active' : ''}`}>
      <div className="tab-content" onMouseLeave={close}>
        <div className="tab-head m_head m_head-1">
          <div className="wrapper wrapper-lg pd-x-md">
            <ul className="d-flex">
              {Object.keys(menuData).map((tabName, index) => (
                <li
                  key={index}
                  className={`tab-item ${activeTab === tabName ? 'active' : ''}`}
                  onClick={() => setActiveTab(tabName)}
                >
                  {tabNameToTitleMap[tabName]}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {
          <div className="tab-body m_body m_body-1">
            {Object.keys(menuData).map((tabName, index) => {
              const section = menuData[tabName];
              return (
                <MegaMenuTab
                  key={index}
                  data={section}
                  type={'read-section'}
                  isActive={activeTab === tabName}
                  viewAllLinkPrefix={`/read/${sectionNameToUrlMap[tabName]}`}
                />
              );
            })}
          </div>
        }
      </div>

      <div className="overlay" onClick={close}></div>
    </div>
  );
}
