import React, { useState, useEffect } from 'react';
import iconArrowLeftAlt from '../../../images/icon-arrow-left-alt.svg';

export default function Level2Menu({ active, setActive, setActiveNextLevel, data }) {
  const [menuData, setMenuData] = useState();

  useEffect(() => {
    setMenuData(active);
  }, [active]);

  const showMenu = !!menuData;

  return (
    <div className={`popup popup-nav popup-sub-menu-primary ${showMenu ? 'active' : ''}`}>
      <div className="wrapper wrapper-xl pd-x-md nav-top">
        <div className="block d-flex">
          <a className="nav-back cursor-pointer" onClick={() => setActive()}>
            <img src={iconArrowLeftAlt} alt="Left Arrow" />
          </a>
          <div className={`nav-bars ${showMenu ? 'open' : ''}`} onClick={() => setActive()} />
        </div>
      </div>
      <div className="nav-bottom">
        <div className="d-flex">
          <div className="block">
            <ul className="menu-primary">
              {menuData &&
                Object.keys(menuData).map((tabName, index) => (
                  <li className="hasChild" key={index.toString()}>
                    <a>
                      {tabName && tabName.split(': ').length > 1 ? (
                        <div className="submenu__series">
                          <div className="submenu__series--opacity-low">{tabName.split(': ')[0]}</div>
                          <div>{tabName.split(': ')[1]}</div>
                        </div>
                      ) : (
                        <div className="submenu__series">
                          <div>{tabName}</div>
                        </div>
                      )}
                    </a>
                    <span className="after cursor-pointer" onClick={() => setActiveNextLevel(menuData[tabName])} />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="popup-overlay" onClick={() => setActive()} />
    </div>
  );
}
