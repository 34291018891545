import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
// import Image from '../Image/Image'
import logo from '../../images/logo.svg';
import iconArrowUp from '../../images/icon-arrow-up.svg';
import iconFacebook from '../../images/icon-facebook.svg';
import iconInstagram from '../../images/icon-instagram.svg';
import iconTwitter from '../../images/icon-twitter.svg';
import iconPatreon from '../../images/icon-patreon.svg';
import iconYoutube from '../../images/icon-youtube.svg';
import { flags } from '../Homepage/FlagsLists';
import SelectLanguage from './SelectLanguage';

export default function FooterComponent() {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let cancel = false;
    function handleScroll(status) {
      if (cancel) return;
      const scroll = window.scrollY;
      if (scroll >= 100) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    }

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      cancel = true;
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer id="footer" className="site-footer">
      <div className="wrapper wrapper-xl pd-x-md">
        <div className="footer-top">
          <div className="block">
            <div className="footer-logo brand-logo">
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <div className="footer-text footer-note">
              <p>
                A multi-faceted educational tool designed to help you understand your spiritual heritage and explore the
                links between the past, present and future.
              </p>
            </div>
            <ul className="footer-nav footer-nav-1">
              <li className="fn-team">
                <Link to="/team/">The Team</Link>
              </li>
              <li className="fn-shop">
                <Link to="/shop/">Shop</Link>
              </li>
              <li className="fn-give">
                <Link to="/give/">Give</Link>
              </li>
            </ul>
            <ul className="footer-nav footer-nav-2">
              <li className="fn-about">
                <Link to="/about/">About</Link>
              </li>
              <li className="fn-contact">
                <Link to="/contact/">Contact</Link>
              </li>
              <li>
                <Link to="/language/">Languages</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="block">
            <div className="footer-logo"></div>
            <div className="footer-text footer-copyright">
              <p>© {new Date().getFullYear()} - Present Lineage Journey</p>
            </div>
            <ul className="footer-social">
              <li>
                <a href="https://www.facebook.com/lineagejourney/" target="_blank">
                  <img src={iconFacebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/lineagejourney/" target="_blank">
                  <img src={iconInstagram} alt="Instagram" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/lineagejourney" target="_blank">
                  <img src={iconTwitter} alt="Twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.patreon.com/lineagejourney" target="_blank">
                  <img src={iconPatreon} alt="Patreon" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UChwz5_GZN655houtFoVKQ7w" target="_blank">
                  <img src={iconYoutube} alt="Youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div href="#" className={`btn btn-scroll-top  ${isScrolling ? 'scrolling' : ''}`} onClick={scrollToTop}>
        <img src={iconArrowUp} alt="Arrow Up" />
      </div>
    </footer>
  );
}
