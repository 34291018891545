import React from 'react';
import SEO from './common/SEO';
import HeaderComponent from '../../components/Header/HeaderComponent';
import FooterComponent from '../../components/Footer/FooterComponent';
import '../../styles/style.scss';

export default function DefaultLayoutComponent({
  children,
  seo = {},
  description,
  title,
  openGraphImage,
  timeline,
  isForTimelineGraph,
  gcbooks,
  backUrl,
  gcbooksList,
}) {
  return (
    <>
      <SEO
        seoData={seo}
        title={title}
        description={description}
        header={{
          siteTitle: 'Lineage Journey',
        }}
        openGraphImage={openGraphImage}
      />
      <HeaderComponent
        backUrl={backUrl}
        gcbooks={gcbooks}
        isForTimelineGraph={isForTimelineGraph}
        timeline={timeline}
      />
      {children}
      {!timeline || gcbooksList ? <FooterComponent /> : ''}
    </>
  );
}
