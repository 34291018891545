import React, { useState } from 'react';

import MegaMenuTab from './MegaMenuTab';
import { getFormatedEpisodesCategoryTitle } from '../../../../utils/watch';

const categorySlugToURL = {
  '360-tours': '360',
};

const getURLForTabName = (tabName) => {
  if (tabName === 'bible') {
    return '/sub_periods';
  }

  if (tabName === 'series') {
    return '/episode_category';
  }

  if (tabName === 'peopleAndplaces') {
    return null;
  }

  return `/episode_category/${categorySlugToURL[tabName] || tabName}`;
};

export default function WatchMegaMenu({ isShown, close, menuData }) {
  const [tabs, _] = useState(menuData);
  const [activeTab, setActiveTab] = useState('series');

  return (
    <div className={`mega-menu ${isShown ? 'active' : ''}`}>
      <div className="tab-content" onMouseLeave={close}>
        <div className="tab-head m_head m_head-1">
          <div className="wrapper">
            <ul className="d-flex">
              {Object.keys(tabs).map((tabName, index) => (
                <li
                  key={index}
                  className={`tab-item ${activeTab === tabName ? 'active' : ''}`}
                  onClick={() => setActiveTab(tabName)}
                >
                  {getFormatedEpisodesCategoryTitle(tabName)}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="tab-body m_body m_body-1">
          {Object.keys(menuData).map((tabName, index) => {
            return (
              <MegaMenuTab
                key={index}
                data={menuData[tabName]}
                isActive={activeTab === tabName}
                tabName={tabName}
                type={'watch-section'}
                viewAllLinkPrefix={getURLForTabName(tabName)}
              />
            );
          })}
        </div>
      </div>
      <div className="overlay" onClick={close}></div>
    </div>
  );
}
