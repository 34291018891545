const sectionNameToUrlMap = {
  blog: 'blog',
  'season-1': 'season1',
  'season-2': 'season2',
  'season-3': 'season3',
  '360-tours': '360',
  'the-7-churches-of-revelation': '7-churches',
  'the-great-controversy': 'the-great-controversy',
};

module.exports = sectionNameToUrlMap;
