const TimeFrameSortMap = {
  'creation-to-moses': 1,
  'joshua-to-david': 2,
  'ad-100-500': 3,
  'ad-500-1200': 4,
  'ad-1200-1500': 5,
  'ad-1500-1530': 6,
  'ad-1530-1550': 7,
  'ad-1550-1600': 8,
  'ad-1600-1800': 9,
  'ad-1800-1850': 10,
  'ad-1850-1880': 11,
  'ad-1880-1900': 12,
  'ad-1900-onwards': 13,
};

export default TimeFrameSortMap;
