import React, { useState } from 'react';

import sectionNameToUrlMap from '../../../../map/readSectionNameToURLMap';

import MegaMenuSubTab from './MegaMenuSubTab';
import { season3SubCategories } from '../../../../utils/watch';

const subTabNameToTitleMap = {
  [`season-1`]: ': THE REFORMATION',
  [`season-2`]: ': THE MOVEMENT',
  [`season-3`]: ': THE BEGINNING',
};

const ArraySubTab = ({ data, viewAllLink, isActive, sliceLength, shouldSliceData }) => {
  return (
    <MegaMenuSubTab
      data={data}
      isActive={isActive}
      viewAllLink={viewAllLink}
      isBlog={true}
      shouldSliceData={shouldSliceData}
      sliceLength={sliceLength}
    />
  );
};

export default function MegaMenuTab({ data, isActive, viewAllLinkPrefix, tabName, type }) {
  const [firstSubSection] = Object.keys(data);
  const [activeSubTab, setActiveSubTab] = useState(firstSubSection);
  const [activeSubTabForSeason3, setActiveSubTabForSeason3] = useState('manger-to-messiah');

  // Blog doesn't have subSection so is returned as array.
  let isArray;
  isArray = Array.isArray(data);

  const isTimeFrame = tabName === 'timeFrame';
  // Even though timeFrame is Object. It should be treated as array.
  if (isTimeFrame) {
    isArray = true;
  }

  // If read section then show 8 elements else if watch section then show 4 elements.
  const sliceLength = type === 'read-section' ? 8 : 4;

  return (
    <div className={`tab-content ${isActive ? 'active' : 'd-none'}`}>
      {!isArray && (
        <div className="tab-head-inner m_head m_head-2">
          <div className="wrapper wrapper-xl pd-x-md">
            <ul className="d-flex">
              {Object.keys({ ...data, ...data }).map((subTabName, index) => {
                return (
                  <li
                    key={index}
                    className={`tab-item-inner ${activeSubTab === subTabName ? 'active' : ''}`}
                    onClick={() => setActiveSubTab(subTabName)}
                  >
                    {subTabName.replace(/-/g, ' ')}
                    {`${subTabNameToTitleMap[subTabName] || ''}`}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      {activeSubTab === 'season-3' && tabName !== 'bts' ? (
        <>
          <div className="tab-head-inner m_head m_head-2">
            <div className="wrapper wrapper-lg pd-x-md">
              <ul className="d-flex">
                {Object.keys(season3SubCategories).map((subTabName, index) => {
                  return (
                    <li
                      key={index}
                      className={`tab-item-inner ${activeSubTabForSeason3 === subTabName ? 'active' : ''}`}
                      onClick={() => setActiveSubTabForSeason3(subTabName)}
                    >
                      {`${season3SubCategories[subTabName] || ''}`}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <>
            {Object.keys(season3SubCategories).map((subTabName, index) => {
              if (subTabName === activeSubTabForSeason3) {
                return (
                  <div className="tab-body-inner m_body-2" key={index}>
                    <MegaMenuSubTab
                      data={data['season-3'].filter((item) =>
                        item.data.categories.some((item) => item.episodeCategory.categoryType === subTabName)
                      )}
                      // key={index}
                      shouldSliceData={true}
                      sliceLength={sliceLength}
                      subTabName={subTabName}
                      isActive={activeSubTabForSeason3 === subTabName}
                      activeSubTab={activeSubTabForSeason3}
                      viewAllLink={`/episode_category/${subTabName}`}
                    />
                  </div>
                );
              }
              return null;
            })}
          </>
        </>
      ) : (
        <div className="tab-body-inner m_body-2">
          {!isArray ? (
            Object.keys(data).map((subTabName, index) => {
              const subTabDetails = data[subTabName];
              const formattedURLName = tabName === 'bts' ? '' : sectionNameToUrlMap[subTabName] || subTabName;

              return (
                <MegaMenuSubTab
                  data={subTabDetails}
                  key={index}
                  shouldSliceData={['people', 'places'].includes(subTabName) ? false : true}
                  sliceLength={sliceLength}
                  subTabName={subTabName}
                  isActive={activeSubTab === subTabName}
                  activeSubTab={activeSubTab}
                  viewAllLink={viewAllLinkPrefix ? `${viewAllLinkPrefix}/${formattedURLName}` : `/${formattedURLName}`}
                />
              );
            })
          ) : isTimeFrame ? (
            <ArraySubTab
              data={Object.keys(data).map((elm) => ({ title: elm, url: `/sub_periods/${elm}` }))}
              viewAllLink={null}
              isActive={isActive}
              shouldSliceData={false}
              sliceLength={sliceLength}
            />
          ) : (
            <ArraySubTab
              data={data}
              isActive={isActive}
              viewAllLink={type === 'blog' ? '/read/blog' : viewAllLinkPrefix}
              sliceLength={sliceLength}
              shouldSliceData={true}
            />
          )}
        </div>
      )}
    </div>
  );
}
