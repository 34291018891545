import React, { useContext } from 'react';
import { AppContext as CurrencyContext } from '../../../apollo/wrap-root-element';
import { getFormatNumberWithCommas } from '../../../modules/timeline/components/EnterButton/utils';

export const currencySymbolMap = {
  EUR: '€',
  USE: '$',
  GBP: '£',
  AUD: '$',
  NZD: '$',
  CAD: '$',
};

const CurrencySwitcher = ({ price, isCommaSeparated }) => {
  const { currency, currenciesRate } = useContext(CurrencyContext);
  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);
  if (isCommaSeparated) {
    return (
      <span>{`${currencySymbolMap[currency] || '$'}${getFormatNumberWithCommas(
        Math.ceil(selectedRate.rate * price)
      )}`}</span>
    );
  }

  return <span>{`${currencySymbolMap[currency] || '$'}${parseFloat((selectedRate.rate * price).toFixed(2))}`}</span>;
};

export default CurrencySwitcher;
