import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import WatchMegaMenu from './MegaMenu/WatchMegaMenu';
import ReadMegaMenu from './MegaMenu/ReadMegaMenu';

export default function NavItem({ url, label, hasChild, activeStateUrls, data }) {
  const [isShown, setIsShown] = useState(false);
  const [isCurrent, setIsCurrent] = useState(false);

  useEffect(() => {
    if (window && window.location) {
      const currentUrl = window.location.href;
      if (typeof url === 'string' && currentUrl.includes(url)) {
        setIsCurrent(true);
      }
      if (Array.isArray(activeStateUrls) && activeStateUrls.find((urlStr) => currentUrl.includes(urlStr))) {
        setIsCurrent(true);
      }
    }
  });

  return (
    <li
      className={`${hasChild ? 'hasChild' : ''} ${isCurrent ? 'current' : ''}`}
      onMouseEnter={hasChild ? () => setIsShown(true) : null}
      onMouseLeave={hasChild ? () => setIsShown(false) : null}
    >
      <Link to={url}>
        {label}
        {hasChild && <span className="icon-dropdown"></span>}
      </Link>
      {hasChild && label === 'Watch' && (
        <WatchMegaMenu isShown={isShown} close={() => setIsShown(false)} menuData={data} />
      )}
      {hasChild && label === 'Read' && (
        <ReadMegaMenu isShown={isShown} close={() => setIsShown(false)} menuData={data} />
      )}
    </li>
  );
}
