import React from 'react';
import { getCountryFlagImageLink } from '../../utils/language';

const FlagsLists = ({ flagLists }) => {
  return (
    <>
      {flagLists.map((item, i) => (
        <img src={getCountryFlagImageLink(item.data.flagCode[0].text)} width="40" height="30" key={i} />
      ))}
    </>
  );
};

export default FlagsLists;
