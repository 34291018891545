import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import iconArrowLeftAlt from '../../../images/icon-arrow-left-alt.svg';

export default function Level3Menu({ active, closeLevel3Modal }) {
  const [menuData, setMenuData] = useState();

  useEffect(() => {
    setMenuData(active);
  }, [active]);

  return (
    <div className={`popup popup-nav popup-sub-menu-primary ${menuData ? 'active' : ''}`}>
      <div className="wrapper wrapper-xl pd-x-md nav-top">
        <div className="block d-flex">
          <a className="nav-back cursor-pointer" onClick={closeLevel3Modal}>
            <img src={iconArrowLeftAlt} alt="Left Arrow" />
          </a>
          <div className={`nav-bars ${menuData ? 'open' : ''}`} onClick={closeLevel3Modal}></div>
        </div>
      </div>
      <div className="nav-bottom">
        <div className="d-flex">
          <div className="block">
            <ul className="menu-primary">
              {menuData && menuData.length > 0
                ? menuData.slice(0, 4).map((item) => {
                    const menuTitle = (item?.data?.title && item?.data?.title[0]?.text) || item?.title?.text;

                    return (
                      <li className="hasChild" key={item.key}>
                        <Link to={item.url}>
                          <div className="submenu__series">
                            <div>{menuTitle}</div>
                          </div>
                        </Link>
                      </li>
                    );
                  })
                : null}
            </ul>
            {menuData && menuData.viewAllLink ? (
              <div className="hasChild h-mt-60 h-color-black h-border-btn">
                <Link to={menuData.viewAllLink}>
                  <div className="submenu__series">
                    <div>VIEW ALL</div>
                  </div>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="popup-overlay" onClick={closeLevel3Modal} />
    </div>
  );
}
