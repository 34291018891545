import React, { useState, useContext } from 'react';

import iconUserAlt from '../../../images/icon-user-alt.svg';
import { AppContext as CurrencyContext } from '../../../apollo/wrap-root-element';
import { toast } from 'react-toastify';
import { navigate } from 'gatsby';
import { Link } from 'gatsby';

const AccountOptions = ({ mode = 'light' }) => {
  const [open, setOpen] = useState(false);

  const { setCustomerTokenDetails, setCurrentUserDetails } = useContext(CurrencyContext);

  return (
    <>
      <div className="currency-main dsp-flx-center">
        <div
          className=" nav-search"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <img src={iconUserAlt} width="24px" alt="User-Account" />
        </div>
        {open && (
          <div className="currency-choices right-36 account-menu-hover" style={{ width: 150 }}>
            <div
              style={{ width: '100%' }}
              className={`currency-choice text-align-center`}
              onClick={() => {
                setOpen(false);
                window.open('https://lineage-journey.myshopify.com/account/login?return_url=%2Faccount');
              }}
            >
              <p className={mode === 'dark' && 'dark'} id="margin-0">
                My Downloadable Products
              </p>
            </div>
            <Link
              style={{ width: '100%' }}
              className={`currency-choice account-menu-hover`}
              to="/books/the-great-controversy"
            >
              <p className={mode === 'dark' && 'dark'}>Study History</p>
            </Link>

            <Link style={{ width: '100%' }} className={`currency-choice account-menu-hover`} to="/donation-history">
              <p className={mode === 'dark' && 'dark'}>Giving History</p>
            </Link>
            <Link
              style={{ width: '100%', textAlign: 'center' }}
              className={`currency-choice account-menu-hover`}
              to="/recurring-donations"
            >
              <p className={mode === 'dark' && 'dark'}>Giving Subscription</p>
            </Link>
            <div
              style={{ width: '100%' }}
              className={`currency-choice `}
              onClick={() => {
                setOpen(false);
                if (typeof window !== 'undefined') {
                  localStorage.removeItem('shopifyAccessToken');
                  localStorage.removeItem('shopifyAccessTokenExpiresAt');
                  setCustomerTokenDetails(undefined);
                  setCurrentUserDetails(undefined);
                  toast.success('Log Out Successful', {
                    hideProgressBar: true,
                    position: 'bottom-center',
                  });
                  navigate('/');
                }
              }}
            >
              <p className={mode === 'dark' && 'dark'}>Logout</p>
            </div>
          </div>
        )}
      </div>
      {open && (
        <div
          className="currency-overlay"
          onClick={() => {
            setOpen(false);
          }}
        ></div>
      )}
    </>
  );
};
export default AccountOptions;
