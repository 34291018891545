import React, { useState, useContext } from 'react';
import { AppContext as CurrencyContext } from '../../../apollo/wrap-root-element';
import Britain from '../../../images/flags/britain.png';
import Usa from '../../../images/flags/usa.webp';
import Euro from '../../../images/flags/eu.svg';
import Australia from '../../../images/flags/aus.svg';
import NewZealand from '../../../images/flags/nz.png';
import Canada from '../../../images/flags/ca.png';
import Icon from '../../../images/icon-play.svg';
import IconDark from '../../../images/icon-play-blk.svg';

const choices = [
  {
    name: 'GBP',
    image: Britain,
  },
  {
    name: 'USD',
    image: Usa,
  },
  {
    name: 'EUR',
    image: Euro,
  },
  {
    name: 'AUD',
    image: Australia,
  },
  {
    name: 'NZD',
    image: NewZealand,
  },
  {
    name: 'CAD',
    image: Canada,
  },
];

const CurrecySelect = ({ mode = 'light' }) => {
  const [open, setOpen] = useState(false);

  const { currency, setCurrency } = useContext(CurrencyContext);
  const flag = choices.find((choice) => choice.name === currency)?.image;
  return (
    <>
      <div className="currency-main">
        <div
          className="currency-current"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <img src={flag} />
          <p className={mode === 'dark' ? 'dark' : ''}>{currency}</p>
          <img src={mode === 'light' ? Icon : IconDark} className={open ? 'up pointer' : 'down pointer'} />
        </div>{' '}
        {open && (
          <div className="currency-choices">
            {choices.map((choice, index) => (
              <div
                key={`currency-${index}`}
                className={`${choice.name === currency ? 'active' : ''} currency-choice`}
                onClick={() => {
                  setCurrency(choice.name);
                  setOpen(false);
                  if (typeof window !== 'undefined') {
                    localStorage.setItem('currency', choice.name);
                  }
                }}
              >
                <img src={choice.image} />
                <p className={mode === 'dark' && 'dark'}>{choice.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {open && (
        <div
          className="currency-overlay"
          onClick={() => {
            setOpen(false);
          }}
        ></div>
      )}
    </>
  );
};
export default CurrecySelect;
