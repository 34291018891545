const { ReadSectionEnum, FEATURED_SECTION } = require('../constants/read');

/**
 * This function is used to normalize flatten prismic response into different read section and subsection.
 * For example:
 * @Prismic Response:
 * [{data: {..., readCategory: [ {featuredCategory: "episodes"} {featuredCategory: "season-1"}]}}]
 *
 * Since readCategory doesn't differentiate between section & subsection, we need to filter subSection by finding section first.
 *
 * @returns
 * {
 *  season-1: {[subSection1]: [data1, data2], [subSection2]: [data1, data2] }
 *  season-2: {[subSection1]: [data1, data2]}
 *  blog: [data1, data2] //Note: Blog doesn't have sub-section
 * }
 *
 *
 **/
const normalizeReadCategories = (readCategories, isDataDetailsRequired = true) => {
  return readCategories.reduce((acc, value) => {
    const categories = value?.data?.readCategories?.map((elem) => elem?.readCategory?.featuredCategory);
    const section = !!categories ? categories?.find((elm) => Object.values(ReadSectionEnum).includes(elm)) : [];
    const { url, id } = value;
    let details = isDataDetailsRequired ? { ...value?.data, id, url } : { id, url };
    const subSection = categories?.filter((elm) => elm !== section && elm !== FEATURED_SECTION);

    // Blog's don't have sub-category.
    if (!!subSection?.length) {
      subSection.map((subSection) => {
        details = { ...details };
        acc = {
          ...acc,
          [section]: !!acc[section]
            ? {
                ...acc[section],
                [subSection]: !!acc[section][subSection] ? [...acc[section][subSection], details] : [details],
              }
            : { [subSection]: [details] },
        };
      });
    } else {
      details = { ...details };
      acc = {
        ...acc,
        [ReadSectionEnum.BLOG]: !!acc[ReadSectionEnum.BLOG] ? [...acc.blog, details] : [details],
      };
    }

    return acc;
  }, {});
};

module.exports = {
  normalizeReadCategories,
};
