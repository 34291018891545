import React from 'react';
import { Link } from 'gatsby';

import { getVideoThumbnail } from '../../../../utils/functions';
import { getFormatedEpisodesCategoryTitle } from '../../../../utils/watch';

const getThumbnailURL = (data) => {
  if (data?.image?.fluid) {
    return data.image.fluid.src;
  }

  if (data?.backgroundImage?.url) {
    return data.backgroundImage.url;
  }

  const [videoId] = data?.youtubeId || [];

  const thumbnailUrl = !!data?.thumbnailImage?.url ? data?.thumbnailImage.url : getVideoThumbnail(videoId?.text || '');

  return thumbnailUrl;
};

const getTitleText = (data) => {
  if (data?.data) {
    return data?.data?.title[0].text;
  }

  if (data?.title?.text) {
    return data.title.text;
  }

  return data?.title;
};

export default function MegaMenuSubTabItem({ columns, disable, data }) {
  let titleText = getFormatedEpisodesCategoryTitle(getTitleText(data));

  const imageUrl = getThumbnailURL(!!data?.data ? data.data : data);

  return (
    <Link className={`cols cols-${columns} items`} to={data?.url}>
      {imageUrl && (
        <div className="list-figure media ratio_16-9">
          <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${imageUrl})` }}></div>
        </div>
      )}
      <div
        className={`list-title ${data?.url == '/video_episodes/william-tyndale-t-shirt/' ? 'nolinebreak' : ''} ${
          disable ? 'disabled' : ''
        }`}
      >
        {titleText}
      </div>
    </Link>
  );
}
