import React, { useContext } from 'react';
import { Link } from 'gatsby';

import CartItem from './CartItem';
import iconArrowLeftAlt from '../../images/icon-arrow-left-alt.svg';
import CurrencySwitcher from '../Header/currencyswitcher/CurrencySwitcher';
import { AppContext as CurrencyContext } from '../../apollo/wrap-root-element';
import { useCartCount, useCartItems, useCartTotals, useCheckout } from '../../context/storeContext';

const getCustomAttributes = (lineItems) => {
  return lineItems.map((lineItem) => {
    if (!lineItem?.customAttributes?.length) {
      return;
    }

    const price = !!lineItem?.variant ? lineItem.variant.price : 'no price';
    const image = !!lineItem?.variant ? lineItem.variant.image.src : 'no images';

    const customAttrObj = lineItem.customAttributes.find((elm) => elm.key === 'customAttr');
    const customAttrValues = !!customAttrObj?.value ? JSON.parse(customAttrObj.value) : {};

    let itemDetails = {
      price,
      image,
      customAttr: customAttrValues,
      id: lineItem.id,
      title: lineItem.title,
      quantity: lineItem.quantity,
    };

    return itemDetails;
  });
};

export default function CartComponent({ opened, onClose, getCart }) {
  const lineItems = useCartItems();
  const cartTotal = useCartTotals();
  const cartCount = useCartCount();
  const checkout = useCheckout();
  const { currency } = useContext(CurrencyContext);

  const cartItems = getCustomAttributes(!!lineItems ? lineItems : []);

  const showCurrencyMessage = currency !== 'USD';
  return (
    <div className={`popup popup-nav popup-cart ${opened ? 'active' : ''}`}>
      <div className="pd-x-lg nav-top">
        <div className="block d-flex">
          <a className="nav-back" onClick={onClose}>
            <img src={iconArrowLeftAlt} alt="Left Arrow" />
          </a>
          <div className={`nav-bars ${opened ? 'open' : ''}`} onClick={onClose} />
        </div>
      </div>
      <div className="nav-bottom cart">
        <div className="cart-head">
          <div className="cart-title">Shopping Cart</div>
          <div className="cart-item">
            <span className="cart-counter">{cartCount ? `${cartCount} Items` : '0 Item'}</span>
          </div>
        </div>
        <div className="cart-body">
          {!!cartItems?.length &&
            cartItems.map((cartItem, index) => <CartItem item={cartItem} key={`${index}-${cartItem?.id}`} />)}
        </div>
        <div className="cart-footer">
          <div className="cart-total">
            <div className="cart-total-text cart-product-total">
              Total products
              <span className="cart-product-total-amount ">{<CurrencySwitcher price={cartTotal || '-'} />}</span>
            </div>
            <div className="cart-total-text cart-shipping">
              Shipping - <span className="cart-shipping-amount">calculated during checkout</span>
            </div>
            <div className="cart-total-text cart-grand-total">
              Total {<CurrencySwitcher price={cartTotal || '-'} />}
            </div>
          </div>
          {showCurrencyMessage && (
            <div className="currency-note-section">
              <p className="currency-info">
                Lineage Journey process all orders in US dollars. Although your shopping is displaying in {currency} for
                ease of calculation, you will be checked out in US dollars using today's exchange rate.
              </p>
            </div>
          )}
          <div className="cart-checkout">
            <Link to="/shop/" className="btn-continue">
              Continue shopping
            </Link>
            {cartCount > 0 && (
              <a href={checkout.webUrl} className="btn btn-md btn-primary-ii btn-checkout">
                Go To Checkout
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="popup-overlay" onClick={onClose}></div>
    </div>
  );
}
