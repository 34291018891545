import React, { useState } from 'react';
import { navigate } from 'gatsby';
import iconArrowRight from '../../../images/icon-arrow-right.svg';

export default function SearchbarComponent({ opened, onClose }) {
  const [keyWord, setKeyWord] = useState('');

  const navigateToSearchPage = () => {
    navigate(`/search?keyword=${keyWord}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      navigateToSearchPage();
    }
  };

  return (
    <div className={`popup popup-nav popup-search-bar ${opened ? 'active' : ''}`}>
      <div className="block">
        <div className="wrapper wrapper-xl pd-x-md nav-top">
          <div className="d-flex">
            <div className="search-box">
              <div className="search-box_bar">
                <input
                  type="search"
                  placeholder="Search"
                  className="search-box_input"
                  value={keyWord}
                  onChange={(e) => setKeyWord(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="search-box_button">
                <div className="btn-search" onClick={navigateToSearchPage}>
                  <img src={iconArrowRight} alt="Arrow Right" />
                </div>
              </div>
            </div>
            <div className={`nav-bars ${opened ? 'open' : ''}`} onClick={onClose}>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div className="popup-overlay" onClick={onClose}></div>
    </div>
  );
}
