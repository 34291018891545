const { languageCategoryEnum } = require('../constants/language');

/**
 * This function is used to normalize flatten prismic response into language category and the seasons with episodes that the language contains.
 * For example:
 * @Prismic Response:
 * [{data: {..., categories: [ {episodesCategory: "slug"} {episodesCategory: "polish"}]}}]
 *
 * Since the query prismicNonEngEpisodes doesn't differentiate between language & episodes, we need to filter episodes by finding language first.
 *
 * @returns
 * {
 *  polish: {'season-1': [data1, data2], 'season-2': [data1, data2] }
 *  italian: {'season-1': [data1, data2]}
 * }
 *
 *
 **/

const normalizeLanguageEpisodes = (prismicNonEngEpisodes) => {
  return prismicNonEngEpisodes.nodes.reduce((acc, value) => {
    const categories = value?.data?.categories.map((elm) => elm?.episodeCategory?.slug).filter((e) => e);
    //Get only languages eg.polish, spanish, italian.
    const languageCategory = !!categories
      ? categories.find((elm) => Object.values(languageCategoryEnum).includes(elm))
      : [];
    //Get only seasons, eg. season-1, season-2.
    const episodesCategory = categories.filter((elm) => elm !== languageCategory);
    const details = prismicNonEngEpisodes?.nodes?.filter(
      (elm) =>
        elm.data.categories[0].episodeCategory.slug === languageCategory &&
        elm.data.categories[1].episodeCategory.slug === episodesCategory
    );

    if (!!languageCategory) {
      acc = {
        ...acc,
        [languageCategory]: !!acc[languageCategory]
          ? {
              ...acc[languageCategory],
              [episodesCategory]: !!acc[languageCategory][episodesCategory]
                ? [...acc[languageCategory][episodesCategory]]
                : [details],
            }
          : { [episodesCategory]: [details] },
      };
    }

    return acc;
  }, {});
};

/**
 * @param  {} languageLists
 * @param  {} dubbedEpisodes
 */
const getLanguageLists = (languageLists, dubbedEpisodes) => {
  return languageLists.filter((item) => {
    return dubbedEpisodes.some((episodes) => {
      return episodes.data.categories.some((category) => category?.episodeCategory?.uid === item?.uid);
    });
  });
};

/**
 * @param  {} countryCode
 */
const getCountryFlagImageLink = (countryCode) => {
  return `https://flagcdn.com/h24/${countryCode}.png`;
};

module.exports = { normalizeLanguageEpisodes, getCountryFlagImageLink, getLanguageLists };
