import { Link, useStaticQuery, graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';

import NavItem from './Navbar/NavItem';
import Plus from '../TimelineBarChart/plus.png';
import Back from '../../images/TimeLineBack.svg';

import Minus from '../TimelineBarChart/minus.png';
import logo from '../../images/logo.svg';
import CartComponent from '../Cart/CartComponent';
import iconCartAlt from '../../images/icon-cart-alt.svg';
import iconUserAlt from '../../images/icon-user-alt.svg';
import iconSearchAlt from '../../images/icon-search-alt.svg';
import SidebarComponent from './Sidebar/SidebarComponent';
import { useCartCount } from '../../context/storeContext';
import { normalizeReadCategories } from '../../utils/read';
import TimeFrameSortMap from '../../map/timeFrameSortMap';
import SearchbarComponent from './Searchbar/SearchbarComponent';
import CurrencySelect from './currencyswitcher/currency-select';
import AccountOptions from './currencyswitcher/AccountOptions';

import { sortBySeasonMap, ReadSectionSortMap } from '../../map/readSectionSortMap';
import { normalizeWatchCategories, normalizePeople, normalizePlaces, sortNormalizedWatchData } from '../../utils/watch';
import { AppContext } from '../../apollo/wrap-root-element';
import CustomizedSwitches from '../switcher';
import LeftArrow from '../../images/svg-gcbooks/left-arrow';

const orderReadItems = (readData) => {
  let result = {};

  // Order categories.
  const readDataWithOrderedCategories = Object.keys(readData)
    .sort((x, y) => {
      return ReadSectionSortMap[x] - ReadSectionSortMap[y];
    })
    .reduce((obj, key) => {
      obj[key] = readData[key];

      return obj;
    }, {});

  // Order sub-categories.
  for (const [key, value] of Object.entries(readDataWithOrderedCategories)) {
    const orderedSubCategory = Object.keys(value)
      .filter((elm) => elm !== 'blog')
      .sort((x, y) => {
        const requiredSortMap = sortBySeasonMap[key];

        if (!!requiredSortMap) {
          return requiredSortMap[x] - requiredSortMap[y];
        }
      })
      .reduce((obj, key) => {
        obj[key] = value[key];

        return obj;
      }, {});

    result[key] = orderedSubCategory;
  }

  result['blog'] = readData['blog'];

  return result;
};

export default function HeaderComponent(props) {
  const {
    isEventChecked,
    setIsEventChecked,
    isPeopleChecked,
    setIsPeopleChecked,
    skipTime,
    setSkipTime,
    zoomValue,
    setZoomValue,
    currentUserDetails,
    scrolledDirection,
    scrollPosition,
    deviceIsTab,
  } = React.useContext(AppContext);
  const [isScrolling, setIsScrolling] = useState(false);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [searchbarOpened, setSearchbarOpened] = useState(false);
  const [cartOpened, setCartOpened] = useState(false);

  const cardCount = useCartCount();
  const { timeline, isForTimelineGraph, gcbooks, backUrl } = props;

  useEffect(() => {
    function handleScroll() {
      const scroll = window.scrollY;
      if (scroll >= 25) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
      var pageBannerDivs = document.getElementsByClassName('page-banner');
      if (pageBannerDivs && pageBannerDivs[0])
        pageBannerDivs[0].style.backgroundPosition = 'center ' + -scroll / 3 + 'px';
    }
    window.addEventListener('scroll', handleScroll, true);
    const cleanup = () => {
      window.removeEventListener('scroll', handleScroll);
    };
    return cleanup;
  }, []);

  const queryData = useStaticQuery(graphql`
    {
      readCategories: allPrismicRead(
        filter: {
          data: {
            read_categories: {
              elemMatch: { read_category: { slug: { in: ["season-1", "season-2", "season-3", "blog"] } } }
            }
          }
        }
        sort: { order: ASC, fields: data___priority }
      ) {
        nodes {
          data {
            title {
              text
            }
            readCategories: read_categories {
              readCategory: read_category {
                featuredCategory: slug
                id
              }
            }
            priority
          }
          url
          id
        }
      }

      people: allPrismicPeople(sort: { fields: data___title___text, order: ASC }) {
        edges {
          node {
            data {
              image {
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              metaDescription: meta_description {
                text
              }
              priority
              summary {
                text
              }
              title {
                text
              }
              birthdate
              dateOfDeath: date_of_death
            }
            url: uid
          }
        }
      }

      places: allPrismicPlaces(sort: { order: ASC, fields: data___place_name___text }) {
        edges {
          node {
            id
            data {
              backgroundImage: background_image {
                url
              }
              placeName: place_name {
                text
              }
            }
            url: uid
          }
        }
      }

      tourLanding: allPrismicTourLanding {
        nodes {
          id
        }
      }

      tourData: allPrismicTour {
        nodes {
          uid
        }
      }

      totalWatchData: allPrismicEpisodes(
        filter: {
          data: {
            categories: {
              elemMatch: {
                episodes_category: {
                  slug: {
                    in: [
                      "season-1"
                      "season-2"
                      "season-3"
                      "adverts"
                      "trailers"
                      "the-7-churches-of-revelation"
                      "the-great-controversy"
                      "360-tours"
                      "documentaries"
                      "locations-from-the-sky"
                    ]
                  }
                }
              }
            }
            language: { eq: "English" }
          }
        }
        sort: { fields: data___priority, order: ASC }
      ) {
        nodes {
          data {
            categories {
              episodeCategory: episodes_category {
                categoryType: slug
              }
            }
            priority
            youtubeId: youtube_id {
              text
            }
            bts
            subPeriods: sub_period {
              subPeriod: sub_period1 {
                slug
              }
            }
            title {
              text
            }
            thumbnailImage: thumbnail_image {
              url
            }
          }
          slugs
          uid
        }
      }
    }
  `);

  const readData = normalizeReadCategories(queryData?.readCategories?.nodes, true);
  const orderedReadData = orderReadItems(readData);
  const watchData = normalizeWatchCategories(queryData?.totalWatchData?.nodes, true, false, true);
  const normalizedPeople = normalizePeople(queryData?.people?.edges);
  const normalizedPlaces = normalizePlaces(queryData?.places?.edges);

  const people = Object.keys(normalizedPeople?.people).map((elm) => ({
    title: elm,
    url: `/people/${elm.toLocaleLowerCase().replace(/\s/g, '-')}`,
  }));

  const places = Object.keys(normalizedPlaces?.places).map((elm) => ({
    title: elm,
    url: `/places/${elm.toLocaleLowerCase().replace(/\s/g, '-')}`,
  }));

  const orderedTimeFrameData = Object.keys(watchData.timeFrame)
    .sort((x, y) => {
      return TimeFrameSortMap[x] - TimeFrameSortMap[y];
    })
    .reduce((obj, key) => {
      obj[key] = watchData.timeFrame[key];

      return obj;
    }, {});

  const totalNormalizedData = sortNormalizedWatchData(
    { ...watchData, peopleAndplaces: { people, places }, timeFrame: orderedTimeFrameData },
    true
  );

  const tourExists = queryData?.tourLanding?.nodes?.length > 0 && queryData?.tourData?.nodes?.length > 0;

  const urls = [
    {
      key: 'watch',
      url: '/watch',
      activeStateUrls: ['/time_period_cat/', '/video_episodes/', '/episode_category/', '/people/', '/place/'],
      label: 'Watch',
      hasChild: true,
      data: totalNormalizedData,
    },
    {
      key: 'read',
      url: '/read',
      activeStateUrls: ['/read_term/'],
      label: 'Read',
      hasChild: true,
      data: orderedReadData,
    },
    {
      key: 'podcast',
      url: '/podcast',
      label: 'Listen',
    },
    {
      key: 'study',
      url: '/books/the-great-controversy',
      label: 'Study',
    },
    {
      key: 'shop',
      url: '/shop',
      label: 'Shop',
    },
  ];

  tourExists && urls.push({ key: 'tours', url: '/tours', label: 'Tours' });

  const onZoomCLicked = (zoomShiftValue, skipShiftValue) => {
    const newZoomShiftValue = parseInt(zoomValue) - zoomShiftValue;
    const newSkipShiftValue = parseInt(skipTime) + skipShiftValue;
    if (0 < newSkipShiftValue && newSkipShiftValue < 20) {
      setSkipTime(newSkipShiftValue);
      setZoomValue(newZoomShiftValue);
    }
  };

  return (
    <>
      <header
        id="header"
        className={`site-header ${
          isScrolling ? `scrolling  ${deviceIsTab && scrolledDirection ? 'hide-header' : ''}` : ''
        } ${timeline ? 'timeline-header' : ''}`}>
        <div className={`${timeline ? '' : 'wrapper wrapper-xl '} pd-x-md`} id={gcbooks ? 'wrapper-xl' : ''}>
          <div className="block">
            <div className="navbar-brand brand-logo  " style={{ display: 'flex' }} id={gcbooks ? 'res-brand-logo' : ''}>
              {isForTimelineGraph && (
                <div
                  className=""
                  style={{
                    marginRight: '24px',
                    marginBottom: 'auto',
                    // display: 'inline-block',
                  }}>
                  <Link to={'/timeline-details'}>
                    <img src={Back} alt="right" width="8px" />
                  </Link>
                </div>
              )}
              {gcbooks && (
                <div
                  className=""
                  style={{
                    marginRight: '24px',
                    marginBottom: 'auto',
                    // display: 'inline-block',
                  }}>
                  <Link to={backUrl}>
                    {/* <img src={Back} alt="right" width="8px" /> */}
                    <LeftArrow />
                  </Link>
                </div>
              )}
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>

            {!timeline ? (
              <>
                <div className="navbar-primary">
                  <ul className="menu">
                    {urls.map((url) => (
                      <NavItem {...url} />
                    ))}
                  </ul>
                </div>
                <div className="navbar-right d-flex">
                  <div className="nav-currency">
                    <CurrencySelect />
                  </div>
                  <div className="nav-cart" onClick={() => setCartOpened(!cartOpened)}>
                    <img src={iconCartAlt} alt="Cart" />
                    {!!cardCount && <span className="cart-num">{cardCount}</span>}
                  </div>

                  <div className="nav-search" onClick={() => setSearchbarOpened(!searchbarOpened)}>
                    <img src={iconSearchAlt} alt="Search" />
                  </div>

                  {currentUserDetails?.customer ? (
                    <AccountOptions />
                  ) : (
                    <div className="nav-search">
                      <Link to="/login">
                        <img src={iconUserAlt} width="24px" alt="User-Account" />
                      </Link>
                    </div>
                  )}

                  <Link to="/give" className="btn btn-md btn-primary-ii navbar-primary" style={{ marginRight: '32px' }}>
                    Give
                  </Link>

                  <div className="nav-bars" onClick={() => setSidebarOpened(!sidebarOpened)}>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex g-flex" style={{ justifyContent: 'space-between' }}>
                <Link
                  to={gcbooks ? '/books/the-great-controversy' : '/timeline-details'}
                  className="timeline-header-inner"
                  style={{ marginLeft: 33 }}>
                  <div className="timeline-header-text">{gcbooks ? 'The great controversy' : 'Timeline'}</div>
                </Link>

                <div className="d-flex " style={{ marginLeft: 'auto' }}>
                  {isForTimelineGraph && (
                    <div className="desktop-timeline-action">
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            backgroundColor: '#171717',
                            marginRight: '10px',
                            justifyContent: 'center',
                            padding: '0 10px',
                          }}>
                          <span style={{ color: '#656565', margin: 'auto' }}>PEOPLE</span>
                          <CustomizedSwitches
                            checked={isPeopleChecked}
                            onChange={() => setIsPeopleChecked(!isPeopleChecked)}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            backgroundColor: '#171717',
                            marginRight: '10px',
                            padding: '0 10px',
                          }}>
                          <span style={{ color: '#656565', margin: 'auto' }}>EVENT</span>
                          <CustomizedSwitches
                            checked={isEventChecked}
                            onChange={() => setIsEventChecked(!isEventChecked)}
                          />
                        </div>
                        <button className="plus-minus-arrow" onClick={() => onZoomCLicked(25, 5)}>
                          <img src={Minus} alt="right" />
                        </button>
                        <span className="zoom-span">{zoomValue}%</span>
                        <button className="plus-minus-arrow" onClick={() => onZoomCLicked(-25, -5)}>
                          <img src={Plus} alt="right" />
                        </button>
                      </div>
                    </div>
                  )}
                  {gcbooks ? (
                    currentUserDetails?.customer ? (
                      <AccountOptions />
                    ) : (
                      <div className="nav-search " id="gclogin">
                        <Link to="/login">
                          <img src={iconUserAlt} width="24px" alt="User-Account" />
                        </Link>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                  <div className="nav-bars nav-bars-tl" onClick={() => setSidebarOpened(!sidebarOpened)}>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {gcbooks && <div className="timeline-header-textgc">The great controversy </div>}
        </div>
      </header>

      <SidebarComponent
        opened={sidebarOpened}
        onClose={() => setSidebarOpened(!sidebarOpened)}
        openCart={() => setCartOpened(true)}
        menuData={{ readData: orderedReadData, watchData }}
        isTimeline={timeline}
        gcbooks={gcbooks}
        timeline={timeline}
      />
      <CartComponent opened={cartOpened} onClose={() => setCartOpened(!cartOpened)} />
      <SearchbarComponent opened={searchbarOpened} onClose={() => setSearchbarOpened(!searchbarOpened)} />
    </>
  );
}
