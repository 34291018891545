import React, { useState, useEffect, useContext } from 'react';
import iconArrowLeftAlt from '../../../images/icon-arrow-left-alt.svg';
import { AppContext as CurrencyContext } from '../../../apollo/wrap-root-element';

export default function Level1Menu({ active, setActive, setActiveNextLevel, data }) {
  const [menuData, setMenuData] = useState();

  useEffect(() => {
    setMenuData(data);
  }, [data]);
  return (
    <div className={`popup popup-nav popup-sub-menu-primary ${active ? 'active' : ''}`}>
      <div className="wrapper wrapper-xl pd-x-md nav-top">
        <div className="block d-flex">
          <a className="nav-back cursor-pointer" onClick={() => setActive()}>
            <img src={iconArrowLeftAlt} alt="Left Arrow" />
          </a>
          <div className={`nav-bars ${active ? 'open' : ''}`} onClick={() => setActive()} />
        </div>
      </div>
      <div className="nav-bottom">
        <div className="d-flex">
          <div className="block">
            <ul className="menu-primary">
              {menuData &&
                Object.keys(menuData).map((tabName, index) => (
                  <li className="hasChild" key={index.toString()}>
                    <a>{tabName}</a>
                    <span className={'after cursor-pointer'} onClick={() => setActiveNextLevel(menuData[tabName])} />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="popup-overlay" onClick={() => setActive()} />
    </div>
  );
}
